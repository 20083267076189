<template>
  <div id="app" class="m-5">
    <navigation :page="currentPage" @selected="selectPage" class="content" />
    <page-content :page="currentPage" @selected="selectPage" class="content mt-4" />
    <div class="copyright mt-3">Copyright {{ getYear() }}. © Lankanmon Inc.</div>
  </div>
</template>

<script>
import Navigation from './components/Navigation.vue'
import PageContent from './components/PageContent.vue'

export default {
  name: 'App',

  beforeCreate: function() {
    document.body.className = 'body';
  },

  components: {
    Navigation,
    PageContent,
  },

  data () {
    return {
      currentPage: 'home'
    }
  },

  methods: {
    selectPage(value) {
      this.currentPage = value
    },
    getYear() {
      return new Date().getFullYear()
    },
  }
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;700&display=swap');

#app {
  font-family: 'Source Sans Pro', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  max-width: 800px;
  margin-left: auto !important;
  margin-right: auto !important;
}
.body {
  font-family: 'Source Sans Pro', sans-serif;
  background-color: #7851a9;
}
.content {
  font-family: 'Source Sans Pro', sans-serif;
  background-color: #fff;
}
.copyright {
  color: #000;
}
</style>
