<template>
  <div id="contact" class="py-5">
    <h3>Let's connect...</h3>

    <b-alert :show="submitted" variant="success" class="m-5">
      Your message has been sent. We will get back to you soon!
    </b-alert>

    <b-alert :show="error" variant="danger" class="m-5">
      Something went wrong... Please refresh and try again.
    </b-alert>

    <div class="d-flex justify-content-center">
      <div>
        <b-form v-if="!submitted" @submit.prevent="onSubmit" class="my-5">
          <b-form-group
            id="input-group-1"
            label="Your email"
            label-for="email"
            description="We'll never share your email with anyone else."
            class="my-3"
          >
            <b-form-input
              id="email"
              v-model="form.email"
              type="email"
              placeholder="name@example.com"
              class="shadow-sm"
              required
            ></b-form-input>
          </b-form-group>

          <b-form-group id="input-group-2" label="Your name" label-for="name" class="my-3">
            <b-form-input
              id="name"
              v-model="form.name"
              placeholder="John Doe"
              class="shadow-sm"
              required
            ></b-form-input>
          </b-form-group>

          <b-form-group
            id="input-group-3"
            label="How can we help you?"
            label-for="details"
            class="my-3"
          >
            <b-form-textarea
              id="details"
              v-model="form.details"
              placeholder="I would like help with..."
              class="shadow-sm"
              rows="4"
              max-rows="10"
            ></b-form-textarea>
          </b-form-group>

          <b-row>
            <b-col class="text-center">
              <b-overlay
                :show="submitting"
                rounded
                opacity="0.6"
                spinner-small
                spinner-variant="primary"
                class="d-inline-block"
                @hidden="!submitting"
              >
                <b-button
                  type="submit"
                  :disabled="submitting"
                  class="button shadow mt-3"
                >
                  Send
                </b-button>
              </b-overlay>
            </b-col>
          </b-row>
        </b-form>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'; // Import axios

export default {
  data() {
    return {
      form: {
        email: "",
        name: "",
        details: "",
      },
      responseData: "",
      errorMsg: "",
      submitting: false,
      submitted: false,
      error: false,
    };
  },
  methods: {
    onSubmit() {
      this.submitting = true;
      this.error = false;
      this.submitted = false;

      // Prepare form data as URLSearchParams
      const formData = new URLSearchParams();
      formData.append('name', this.form.name);
      formData.append('email', this.form.email);
      formData.append('body', this.form.details);

      // Send form data using axios with correct headers
      axios
        .post('https://backend.lankanmon.com/send-email', formData, {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          }
        })
        .then((response) => {
          this.responseData = response.data;
          this.submitting = false;
          this.submitted = true;
        })
        .catch((error) => {
          this.errorMsg = error.response?.data?.message || 'Error sending the message';
          this.submitting = false;
          this.error = true;
        });
    },
  },
};
</script>
