<template>
  <div class="d-flex justify-content-between align-content-center">
    <div id="logo" class="px-4 py-2">Lankanmon Inc.</div>
    <div class="d-flex justify-content-between align-content-center px-4 py-3">
      <div class="px-2 navItem" :class="[page === 'home' ? 'navItemSelected' : '']" @click="selectPage('home')">Home</div>
      <div class="px-2 navItem" :class="[page === 'contact' ? 'navItemSelected' : '']" @click="selectPage('contact')">Contact</div>
      <!-- <div class="px-2 navItem" :class="[page === 'tools' ? 'navItemSelected' : '']" @click="selectPage('tools')">Useful Tools</div>
      <div class="px-2 navItem" :class="[page === 'about' ? 'navItemSelected' : '']" @click="selectPage('about')">About</div> -->
    </div>
  </div>
</template>

<script>
export default {
  props: {
    page: {
      type: String,
      default: 'home'
    },
  },

  methods: {
    selectPage(selection) {
      this.$emit('selected', selection)
    }
  }
}
</script>


<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;700&display=swap');
#logo {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 700;
  color:#7851a9;
  font-size: 2rem;
}

.navItem {
  cursor: pointer;
}

.navItemSelected {
  border-bottom: thick solid #7851a9;
}
</style>