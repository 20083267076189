import Vue from 'vue'
import './plugins/vue-axios'
import App from './App.vue'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'


// Import Bootstrap an BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

// Import the functions you need from the SDKs you need
// import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
// const firebaseConfig = {
//   apiKey: "AIzaSyA4WnbQIVH1HphJirRhy-FjswxA6bjGcKA",
//   authDomain: "lankanmon-com-website.firebaseapp.com",
//   projectId: "lankanmon-com-website",
//   storageBucket: "lankanmon-com-website.appspot.com",
//   messagingSenderId: "686593711691",
//   appId: "1:686593711691:web:c1637054756ec2568ef7ce",
//   measurementId: "G-96J78FPJM1"
// };

// // Initialize Firebase
// const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);


// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)

Vue.config.productionTip = false

new Vue({
  render: h => h(App),
}).$mount('#app')
