<template>
  <div>
    <home v-if="page === 'home'" @selected="selectPage"></home>
    <contact v-if="page === 'contact'" @selected="selectPage"></contact>
  </div>
</template>

<script>
import Home from './pages/Home'
import Contact from './pages/Contact'

export default {
  props: {
    page: {
      type: String,
      default: 'home'
    },
  },
  
  components: {
    Home,
    Contact
  },


  data () {
    return {
      currentPage: 'page'
    }
  },

  methods: {
    selectPage(selection) {
      this.$emit('selected', selection)
    }
  }
}
</script>

<style scoped>

</style>