<template>
  <div>
    <div class="p-4">
      <b-carousel
        id="carousel-1"
        background="#ababab"
        style="text-shadow: 1px 1px 2px #000;"
      >
        <b-carousel-slide
          caption="Everything Digital"
          text="We build web applications, mobile applications for iOS & Android, perform quality assuarance & testing and develop custom software for your needs"
          :img-src="cover"
        ></b-carousel-slide>
      </b-carousel>
    </div>

    <div class="line mt-2 pt-4 pb-5 px-4">
       <h3 class="text-left">Our Process</h3>

       <div class="d-flex justify-content-between pt-4">
        <div>
          <div class="p-3">
            <b-icon-journal scale="2"></b-icon-journal>
          </div>
            PLANNING
        </div>

       <div class="pt-4">
         <b-icon-arrow-right scale="1"></b-icon-arrow-right>
       </div>

        <div>
          <div class="p-3">
            <b-icon-bricks scale="2"></b-icon-bricks>
          </div>
            WIREFRAMING
        </div>

       <div class="pt-4">
         <b-icon-arrow-right scale="1"></b-icon-arrow-right>
       </div>

        <div>
          <div class="p-3">
            <b-icon-pencil scale="2"></b-icon-pencil>
          </div>
           DESIGN
        </div>
        
       <div class="pt-4">
         <b-icon-arrow-right scale="1"></b-icon-arrow-right>
       </div>

        <div>
          <div class="p-3">
            <b-icon-code-square scale="2"></b-icon-code-square>
          </div>
            CODING
        </div>
        
       <div class="pt-4">
         <b-icon-arrow-right scale="1"></b-icon-arrow-right>
       </div>

        <div>
          <div class="p-3">
            <b-icon-wifi scale="2"></b-icon-wifi>
          </div>
            DEPLOYMENT
        </div>

       <div class="pt-4">
         <b-icon-arrow-right scale="1"></b-icon-arrow-right>
       </div>

        <div>
          <div class="p-3">
            <b-icon-wrench scale="2"></b-icon-wrench>
          </div>
            MAINTENANCE
          </div>
       </div>
    </div>

    <div class="line mt-3 pt-3 pb-3 px-4">
       <div>
         Need us to help build or enhance your website or application? Contact us today!
       </div>
       <b-button class="mt-4" @click="selectPage('contact')">Contact us</b-button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      cover: require('../../assets/images/covers/cover-m-slim.jpg')
    }
  },

  methods: {
    selectPage(selection) {
      this.$emit('selected', selection)
    }
  }
}
</script>

<style scoped>
  .overlay {
      position: absolute;
      color: white;
      display: block;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      height: 100%;
      width: 100%;
      opacity: 0;
      transition: .5s ease;
      background-color: rgb(0, 0, 0);
  }

  .line {
    border-top: 20px solid #7851a9;
  }
</style>